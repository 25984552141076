import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {Toast ,Locale, PullRefresh, Icon} from 'vant';
import 'vant/lib/index.css';
import enUS from 'vant/es/locale/lang/en-US';
import $pay from './config/pay'

import VConsole from 'vconsole';
Locale.use('en-US', enUS);
let app = createApp(App);
let vconsole = new VConsole()

app.config.globalProperties.$pay = $pay;
app.use(store).use(router).mount('#app')