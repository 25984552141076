import { TonConnectUI } from '@tonconnect/ui'
import { showLoadingToast, closeToast } from 'vant';
// import TonWeb1 from './tonweb.js'
// import $store from '../../store'
// import $g from '../common'
// import { beginCell } from '@ton/ton'
// import TonWeb from 'tonweb'
// let tonweb = new TonWeb();
let $pay = {
    manifestUrl: "https://cookies-7cf.pages.dev/tonconnect-manifest.json",
    // baseUrl:"https://tonwallet.cash",
    baseUrl:"https://staging.tonwallet.cash/api/v1",
    tonConnectUI: ''
}
$pay.tonConnectUI = new TonConnectUI({
    manifestUrl: $pay.manifestUrl
});
// $pay.tonConnectUI.uiOptions = {
//     twaReturnUrl:"https://pay.appstarspay.com"
// }
// $pay.tonConnectUI.onStatusChange(
//     (walletInfo) => {
//         // $pay.getbalance()
//     }
// );
// let requestUrl = $pay.baseUrl;
// function getEvent(url) {
//     return new Promise((resolve, reject) => {
//         var xhr = new XMLHttpRequest();
//         xhr.open('GET',requestUrl + url, true);
//         xhr.onreadystatechange = function () {
//             if (xhr.readyState === XMLHttpRequest.DONE) {
//                 if (xhr.status === 200) {
//                     if (xhr.responseText) {
//                         return resolve({data:JSON.parse(xhr.responseText)})
//                     }
//                     return reject('Request Error')
//                 } else {
//                     return reject('Request Error')
//                 }
//             }
//         };
//         xhr.send();
//     })
// }
// function postEvent(url, params) {
//     return new Promise((resolve, reject) => {
//         var xhr = new XMLHttpRequest();
//         xhr.open('POST', requestUrl + url, true);
//         xhr.setRequestHeader('Content-Type', 'application/json');
//         xhr.onreadystatechange = function () {
//             if (xhr.readyState === XMLHttpRequest.DONE) {
//                 if (xhr.status === 200) {
//                     if (xhr.responseText) {
//                         return resolve({data:JSON.parse(xhr.responseText)})
//                     }
//                     return reject('Request Error')
//                 } else {
//                     return reject('Request Error')
//                 }
//             }
//         };
//         let paramsStr = JSON.stringify(params);
//         xhr.send(paramsStr);
//     })
// }
// function loadingEvent(text = 'loading...'){
//     showLoadingToast({
//         message: text,
//         duration:100000,
//         forbidClick: true,
//         loadingType: 'circular'
//     });
// }
// function clearLoadingEvent(){
//     closeToast()
// }
// $pay.checkConnect = () => {
//     $pay.tonConnectUI.onStatusChange(
//         (walletInfo) => {
//             $store.dispatch('changeWalletInfo',$pay.tonConnectUI.wallet || {})
//         }
//     );
// }
// $pay.createPayload = (text) => {
//     return text;
//     // const body = beginCell()
//     //     .storeUint(0, 32)
//     //     .storeStringTail(text)
//     //     .endCell();
//     //     return body.toBoc().toString("base64")
// }
// $pay.getProofEvent = () => {
//     return new Promise(function (resolve, reject) {
//         getEvent('/ton_proof/generatePayload').then((res) => {
//             if (res.data.code == 0) {
//                 return resolve(res.data.data.payload)
//             } else {
//                 return reject(res.data.msg)
//             }
//         })
//     });
// }
// $pay.checkProofEvent = (walletInfo) => {
//     return new Promise(function (resolve, reject) {
//         postEvent('/ton_proof/checkProof', {proof:walletInfo.proof, wallet:walletInfo.account}).then((res) => {
//             if (res.data.code == 0) {
//                 return resolve(res.data.data)
//             } else {
//                 return reject(res.data.msg)
//             }
//         })
//     });
// }
// $pay.isConnecting = () => {
//     if($pay.tonConnectUI && $pay.tonConnectUI.wallet){
//         return true
//     }else{
//         return false
//     }
// }
$pay.connect = () => {
    return new Promise(async (resolve, reject) => {
        try {
            await $pay.tonConnectUI.connectWallet();
            // let payload = "sadfafddasfdasf";
            // $pay.tonConnectUI.onStatusChange(
            //     (walletInfo) => {
            //         $store.dispatch('changeWalletInfo',$pay.tonConnectUI.wallet || {})
            //     }
            // );
            // if($pay.tonConnectUI){
            //     $pay.tonConnectUI.setConnectRequestParameters({
            //         value: {
            //             tonProof: payload
            //         }
            //     })
            //     await $pay.tonConnectUI.connectWallet();
            // }else{
            //     await $pay.init()
            //     $pay.tonConnectUI.setConnectRequestParameters({
            //         value: {
            //             tonProof: payload
            //         }
            //     })
            //     await $pay.tonConnectUI.connectWallet();
            // }
            
            return resolve($pay.tonConnectUI.wallet)
        } catch (error) {
            console.log('error=====',error)
            return reject('Connect Error')
        }
    });
}
// $pay.pay = (target,num=1, remark = '') => {
//     return new Promise(async (resolve, reject) => {
//         try {
//             let prevConnectTime = window.localStorage.getItem('walletConnetTime');
//             let currentTime = new Date().getTime();
//             if(!prevConnectTime || (currentTime - prevConnectTime > 24*60*60*1000)){
//                 $pay.disconnect()
//                 await $pay.connect();
//                 let payRs = await $pay._pay(target,num, remark)
//                 return resolve(payRs)
//             }
//             if($pay.tonConnectUI.wallet){
//                 let payRs = await $pay._pay(target,num, remark)
//                 return resolve(payRs)
//             }else{
//                 await $pay.connect();
//                 let payRs = await $pay._pay(target,num, remark)
//                 return resolve(payRs)
//             }
//         } catch (error) {
//             return reject(error.message)
//         }
//     })
// }
// $pay._pay = (target,num=1, remark) => {
//     return new Promise(async (resolve, reject) => {
//         if(!$pay.tonConnectUI.account){
//             $pay.showModal()
//             return reject({message: 'Please select wallet'})
//         }
//         if (!num || num <= 0) {
//             return reject({message: 'The number must not be less than 0'})
//         }
//         if (!target) {
//             return reject({message: 'Transfer Failed'})
//         }
//         let numStr = String(num)

//         let uidKey = `devStar_uid`
//         let uid = window.localStorage.getItem(uidKey);
//         // let payload = '';
//         // if(uid && !remark){
//         //     payload = $pay.createPayload(uid)
//         // }
//         // if(remark){
//         //     payload = $pay.createPayload(remark)
//         // }
//         console.log('remark=====',remark)
//         let payload = $pay.createPayload(remark)
//         const transaction = {
//             validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
//             messages: [
//                 {
//                     address: target,
//                     amount: TonWeb.utils.toNano(numStr).toNumber(),
//                     payload: payload
//                 }
//             ]
//         }
//         try {
//             const result = await $pay.tonConnectUI.sendTransaction(transaction);
//             let pT = window.localStorage.getItem('pT');
//             let balance = $pay.getbalance()
//             let walletInfo = $pay.tonConnectUI.walletInfo
//             walletInfo.account = $pay.tonConnectUI.account;
//             walletInfo.balance = balance
//             return resolve({boc:result.boc, pay_token: pT, wallet_info: walletInfo})
//         } catch (err) {
//             return reject(err.message);
//         }
//     });
// }
// $pay.getbalance = async () => {
//     if($pay.tonConnectUI && $pay.tonConnectUI.account){
//         let balance = await tonweb.getBalance($pay.tonConnectUI.account.address);
//         if(isNaN(Number(balance))){
//             balance = 0;
//         }
//         if(balance > 0){
//             balance = tonweb.utils.fromNano(balance) 
//         }
//         // $store.dispatch('changeBalance',balance || '0.000')
//         return balance;
//     }else{
//         $store.dispatch('changeBalance','0.000')
//     }
// }
// $pay.getWalletInfo = () => {
//     return $pay.tonConnectUI.account;
// }
// $pay.disconnect = () => {
//     $pay.tonConnectUI.disconnect();
// }
// $pay.changeAddress = (address) => {
//     if(!address){return address}
//     const _address = new tonweb.utils.Address(address);
//     const friendly = _address.toString(true, true, false, false)
//     return friendly
// }
// $pay.decodeBoc = (msg) => {
//     return new Promise(async (resolve, reject) => {
//         try {
//             initData = "user=%7B%22id%22%3A6758985856%2C%22first_name%22%3A%22Zhang%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22Matinssjjh%22%2C%22language_code%22%3A%22zh-hans%22%2C%22allows_write_to_pm%22%3Atrue%7D&chat_instance=-5210311084625791560&chat_type=private&auth_date=1713939520&hash=0969df36ceab961b513099c2e7a0b410bd3e7259fb9ead227a2cfd8a7cae79de"
//             let initDataJson = $g.fomartInitDataEvent(initData)
//             let defaultParams = {
//                 id:initDataJson.user.id,
//                 _auth:initData,
//             }
//             let params = Object.assign(defaultParams, msg)
//             let result = await postEvent("/wallet/message",params);
//             if(result.data.code == 0){
//                 let hash = result.data.data.hash;
//                 return resolve(hash)
//             }
//             return resolve(result.data.message)
//         } catch (error) {
//             return resolve(error.message)
//         }
//     });
// }

// $pay.getDepositStatus = (params) => {
//     return new Promise(async (resolve, reject) => {
//         let defaultParams = {
//             sender:'',
//             reciver:'',
//             amount:0,
//             comment:"",
//             hash:""
//         }
//         if(!params.boc){return reject('boc not found')}
//         if(!params.sender){return reject('sender not found')}
//         if(!params.reciver){return reject('reciver not found')}
//         if(!params.amount){return reject('amount not found')}
//         if(!params.comment){return reject('comment not found')}
//         try {
//             let hash = await $pay.decodeBoc(params.boc)
//             let query = Object.assign(defaultParams,params)
//             query.hash = hash;
//             let result = await postEvent('/notify/deposit',query);
//         } catch (error) {
//             return reject(error)
//         }
//     });
// }
// $pay.checkConnect()
// export default {
//     connect:$pay.connect,
//     pay:$pay.pay,
//     getbalance: $pay.getbalance,
//     getWalletInfo: $pay.getWalletInfo,
//     disconnect: $pay.disconnect,
//     isConnecting: $pay.isConnecting,
//     changeAddress: $pay.changeAddress,
//     decodeBoc:$pay.decodeBoc
// };
export default {
    connect:$pay.connect
};